// Listens for clicks along the whole li element that represents the "Categories" accordion menu item, not just the "Categories" label text
const categoriesAccordionOnClick = () => {
  const $accordionEl = $('ul#categories-accordion')
  const $accordionLiEl = $('ul#categories-accordion > li')
  const $accordionArrowEl = $accordionEl.find('.accordion-arrow')
  $accordionLiEl.on('click', (event) => {
    if (event.target === $accordionLiEl[0] || event.target === $accordionArrowEl[0]) {
      if ($accordionLiEl[0].ariaExpanded === 'true') {
        $accordionEl.foundation('hideAll')
      } else {
        $accordionEl.foundation('showAll')
      }
    }
  })
}

$(categoriesAccordionOnClick)
